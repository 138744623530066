/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
header {
  background: #fff;
}
header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #333;
  text-decoration: none;
}
.pages{
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

/* Task Editor Styles */
.task-editor {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.task-line {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  cursor: grab;
}

.task-line:hover {
  background-color: #f5f5f5;
}

.task-line input[type="checkbox"] {
  margin-right: 12px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.task-line span {
  flex: 1;
  font-size: 16px;
  line-height: 1.4;
}

.task-done {
  text-decoration: line-through;
  color: #888;
}

.delete-task {
  cursor: pointer;
  color: #999;
  font-size: 20px;
  padding: 0 8px;
  flex: 0 0 auto;
}

.delete-task:hover {
  color: var(--error);
}

.new-task {
  margin-top: 12px;
}

.task-input {
  flex: 1;
  border: none;
  padding: 8px;
  font-size: 16px;
  font-family: inherit;
  background: transparent;
}

.task-input:focus {
  outline: none;
}

.task-input::placeholder {
  color: #999;
}

/* Existing styles */
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

/* Auth form styles */
form.signup, form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: white;
  border-radius: 4px;
}

/* navbar */
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav a, nav button {
  margin-left: 10px;
}
nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
}

.navbar {
  display: flex;
  align-items: right;
  justify-content: space-between;
}

.auth-links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.auth-links a, .auth-links button {
  margin-left: 10px;
}

/* Task Edit Dialog Styles */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dialog-content h2 {
    margin-top: 0;
    margin-bottom: 1.5rem;
    color: var(--primary);
}

.dialog-content .form-group {
    margin-bottom: 1rem;
}

.dialog-content label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--primary);
}

.dialog-content input,
.dialog-content select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.dialog-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
}

.dialog-buttons button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.dialog-buttons button[type="submit"] {
    background: var(--primary);
    color: white;
}

.dialog-buttons button[type="button"] {
    background: #ddd;
    color: #333;
}

/* Task Details Styles */
.task-details {
    background: #fff;
    border-radius: 4px;
    margin: 20px 0;
    padding: 20px;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
    cursor: pointer;
}

.task-time {
    color: #666;
    font-size: 0.9em;
    margin-left: auto;
    margin-right: 12px;
    white-space: nowrap;
}

/* Dashboard Layout */
.dashboard {
    padding: 20px;
    height: calc(100vh - 80px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.dashboard-header h1 {
    margin: 0;
    color: var(--primary);
    font-size: 1.8em;
}

.add-focus-block {
    background: white;
    border: 2px solid var(--primary);
    border-radius: 4px;
    padding: 8px 16px;
    color: var(--primary);
    cursor: pointer;
    font-size: 0.9em;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    gap: 6px;
}

.add-focus-block:hover {
    background: var(--primary);
    color: white;
}

.dashboard-columns {
    display: flex;
    gap: 20px;
    height: 100%;
    overflow-x: auto;
    padding-bottom: 20px;
}

.dashboard-column {
    flex: 0 0 350px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.task-list-column {
    min-width: 350px;
}

.column-header {
    padding: 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.column-header h2 {
    margin: 0;
    color: var(--primary);
    font-size: 1.2em;
}

/* Focus Block Styles */
.focus-block {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.focus-block-header {
    padding: 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.focus-block-title {
    margin: 0;
    color: var(--primary);
    font-size: 1.2em;
    cursor: pointer;
}

.focus-block-title-input {
    font-size: 1.2em;
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 80%;
}

.focus-block-content {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
}

.remove-focus-block {
    background: none;
    border: none;
    color: #999;
    font-size: 1.5em;
    cursor: pointer;
    padding: 0 8px;
}

.remove-focus-block:hover {
    color: var(--error);
}

.add-column {
    flex: 0 0 auto;
    min-width: auto;
    background: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.placeholder-text {
    color: #999;
    text-align: center;
    padding: 20px;
}

/* Update existing task editor styles */
.task-editor {
    height: 100%;
    overflow-y: auto;
    padding: 20px;
}

.tasks-list {
    overflow-y: auto;
}

/* Drag and Drop Styles */
.is-dragging {
    background: #f8f8f8 !important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1) !important;
}

.is-dragging-over {
    background: #f0f9f6;
    border-radius: 4px;
}

.tasks-list-content {
    min-height: 100px;
    padding: 8px;
}

.focus-block-content {
    min-height: 100px;
    padding: 8px;
    background: #fff;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.task-line:active {
    cursor: grabbing;
}

.drag-over {
    background-color: #f0f9f6;
    border-radius: 4px;
    transition: background-color 0.2s;
}

.tasks-list-content,
.focus-block-content {
    min-height: 100px;
    padding: 8px;
    transition: background-color 0.2s;
}

/* Time Total Styles */
.time-total {
    color: #666;
    font-size: 0.9em;
    white-space: nowrap;
    padding: 4px 8px;
    background: #f5f5f5;
    border-radius: 4px;
}

.tasks-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eee;
}

.tasks-list-header h3 {
    margin: 0;
    color: var(--primary);
    font-size: 1.2em;
}

.focus-block-title-row {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
}

.focus-block-title {
    margin: 0;
    flex: 1;
}

/* Timer Styles */
.timer {
    padding: 16px;
    background: #f8f8f8;
    border-radius: 4px;
    margin: 16px;
    text-align: center;
}

.timer h3 {
    margin: 0 0 12px 0;
    color: #333;
}

.time-display {
    font-size: 2em;
    font-weight: bold;
    color: var(--primary);
    margin: 12px 0;
}

.start-button {
    background: var(--primary);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 1.1em;
    cursor: pointer;
    transition: background-color 0.2s;
}

.start-button:hover {
    background: #158f6b;
}

.start-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

/* CheckIn Dialog Styles */
.checkin-dialog {
    width: 90vw;
    max-width: 800px;
    min-height: 60vh;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.checkin-dialog h2 {
    font-size: 2.8em;
    color: var(--primary);
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: center;
}

.checkin-dialog p {
    font-size: 1.8em;
    color: #333;
    margin: 1.5rem 0;
    text-align: center;
}

.checkin-options {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    align-items: center;
    margin-top: 1.5rem;
}

.positive-options {
    display: flex;
    gap: 2rem;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.positive-options button {
    width: 280px;
    padding: 1.4rem;
    border: none;
    border-radius: 12px;
    font-size: 1.4em;
    cursor: pointer;
    transition: all 0.2s;
    text-align: center;
    line-height: 1.3;
}

.continue-button {
    background: var(--primary);
    color: white;
}

.continue-button:hover {
    background: #158f6b;
    transform: scale(1.05);
}

.complete-button {
    background: #4CAF50;
    color: white;
}

.complete-button:hover {
    background: #3d8b40;
    transform: scale(1.05);
}

.distraction-section {
    border-top: 2px solid #eee;
    padding-top: 1.5rem;
    width: 100%;
}

.distraction-section h3 {
    font-size: 1.4em;
    color: #666;
    margin: 0 0 1.2rem 0;
    text-align: center;
}

.distraction-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    width: 100%;
    padding: 0 1rem;
}

.distraction-button {
    background: #ff4444;
    color: white;
    border: none;
    padding: 1rem;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.2s;
    width: 100%;
}

.distraction-button:hover {
    background: #cc0000;
    transform: scale(1.05);
}

/* Media queries for better mobile responsiveness */
@media (max-width: 768px) {
    .checkin-dialog {
        padding: 1.5rem;
        min-height: 70vh;
    }

    .checkin-dialog h2 {
        font-size: 2em;
    }

    .checkin-dialog p {
        font-size: 1.4em;
    }

    .positive-options button {
        width: 100%;
        max-width: 280px;
        padding: 1rem;
        font-size: 1.2em;
    }

    .distraction-buttons {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 480px) {
    .checkin-dialog {
        padding: 1rem;
    }

    .checkin-dialog h2 {
        font-size: 1.8em;
    }

    .checkin-dialog p {
        font-size: 1.2em;
    }

    .positive-options button {
        font-size: 1em;
        padding: 0.8rem;
    }

    .distraction-section h3 {
        font-size: 1.2em;
    }

    .distraction-buttons {
        grid-template-columns: 1fr;
        padding: 0 0.5rem;
    }

    .distraction-button {
        padding: 0.8rem;
        font-size: 0.9em;
    }
}

/* Timer Popup Styles */
.timer-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.timer-popup {
    background: white;
    width: 90%;
    height: 90vh;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.timer-popup-header {
    text-align: center;
    height: 20%;
    display: flex;
    align-items: center;
}

.timer-popup-header h1 {
    color: var(--primary);
    font-size: 3em;
    margin: 0;
}

.timer-popup-task {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 2rem;
}

.timer-popup-task h2 {
    font-size: 2.5em;
    color: #333;
    margin: 0;
}

.timer-popup-circle {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timer-popup-time {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.timer-popup .time-display {
    font-size: 5em;
    font-weight: bold;
    color: var(--primary);
    font-family: monospace;
}

.focus-block-timer {
    padding: 16px;
    text-align: center;
}

.focus-block-timer .start-button {
    background: var(--primary);
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 1.1em;
    cursor: pointer;
    transition: all 0.2s;
    width: 80%;
    max-width: 300px;
}

.focus-block-timer .start-button:hover {
    background: #158f6b;
    transform: scale(1.05);
}

.focus-block-timer .start-button:disabled {
    background: #ccc;
    cursor: not-allowed;
    transform: none;
}